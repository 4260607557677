.videoContainer {
  min-width: calc(20rem - 2rem);
  display: flex;
  justify-content: space-between;
}

.gcMediaController {
  width: 100%;
}

.waveFormSlider {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.audioSlider {
  width: 80%;
}

.videoplayer {
  padding: 10px;
  width: 50%;
}

.cutButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;

  div {
    width: 50%;
    display: flex;
    gap: 10px;
  }
}

.cutButton {
  cursor: pointer;
  padding: 20px;
  border-radius: 10px;
  font-weight: 500;
  float: right;
  margin-right: 40px;

  &:hover {
    background-color: #d9d9d9;
  }
}
