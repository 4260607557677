.workbenchContainer {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.transcriptTextArea {
  width: 100%;
  height: 100%;
  resize: none;
  max-height: 300px;
  overflow: auto;
  border: none;
  outline: none;
  margin: 15px;
}
